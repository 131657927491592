import { default as _91id_93Bl0Vj141SLMeta } from "/src/code/pages/article/[id].vue?macro=true";
import { default as loadingBLFM7eMZEwMeta } from "/src/code/pages/article/loading.vue?macro=true";
import { default as _911_93l2hUnTSpngMeta } from "/src/code/pages/author/[1].vue?macro=true";
import { default as indexn3dxxNC7fFMeta } from "/src/code/pages/authorization/index.vue?macro=true";
import { default as _91subId_93rhBD6tjpQ9Meta } from "/src/code/pages/communities/[id]/[subId].vue?macro=true";
import { default as controlbjWxXHqVRqMeta } from "/src/code/pages/communities/[id]/control.vue?macro=true";
import { default as indexe0c90PIQlaMeta } from "/src/code/pages/communities/[id]/index.vue?macro=true";
import { default as indexAMdJFWU1HaMeta } from "/src/code/pages/communities/index.vue?macro=true";
import { default as indexanrwrqZ6oJMeta } from "/src/code/pages/course/index.vue?macro=true";
import { default as _91route_93t38odbXSLgMeta } from "/src/code/pages/footer/[route].vue?macro=true";
import { default as footerxt28QrVsONMeta } from "/src/code/pages/footer.vue?macro=true";
import { default as indexvkrQ8osdCHMeta } from "/src/code/pages/index.vue?macro=true";
import { default as indexAR5TEoFhioMeta } from "/src/code/pages/messenger/index.vue?macro=true";
import { default as mobile89AiqugFuIMeta } from "/src/code/pages/mobile.vue?macro=true";
import { default as createMqUfPwt1y6Meta } from "/src/code/pages/my-communities/create.vue?macro=true";
import { default as index5niYgASYXuMeta } from "/src/code/pages/my-communities/index.vue?macro=true";
import { default as callbackgXQDxouLnbMeta } from "/src/code/pages/oauth/callback.vue?macro=true";
import { default as indexWQM4Jv4c5ZMeta } from "/src/code/pages/product/index.vue?macro=true";
import { default as cartZP4vWMZakhMeta } from "/src/code/pages/profile/cart.vue?macro=true";
import { default as commentsGWPKc7v5KAMeta } from "/src/code/pages/profile/comments.vue?macro=true";
import { default as coursesYzwA3lXhTaMeta } from "/src/code/pages/profile/courses.vue?macro=true";
import { default as dashboardsVX1e6mn84Meta } from "/src/code/pages/profile/dashboard.vue?macro=true";
import { default as favoriteseAl0RXwWKgMeta } from "/src/code/pages/profile/favorites.vue?macro=true";
import { default as notifications8mb6Mgn6XbMeta } from "/src/code/pages/profile/notifications.vue?macro=true";
import { default as ordersE8iJIvJnvfMeta } from "/src/code/pages/profile/orders.vue?macro=true";
import { default as profileHHQTPbZKI3Meta } from "/src/code/pages/profile.vue?macro=true";
import { default as indexRO7nz4gVqZMeta } from "/src/code/pages/search/index.vue?macro=true";
import { default as testLtJpH2QpdCMeta } from "/src/code/pages/test.vue?macro=true";
import { default as _91username_93qIEc9Hyss9Meta } from "/src/code/pages/users/[username].vue?macro=true";
import { default as indexgFszPmbx7iMeta } from "/src/code/pages/welcome/index.vue?macro=true";
export default [
  {
    name: "article-id",
    path: "/article/:id()",
    component: () => import("/src/code/pages/article/[id].vue")
  },
  {
    name: "article-loading",
    path: "/article/loading",
    component: () => import("/src/code/pages/article/loading.vue")
  },
  {
    name: "author-1",
    path: "/author/:1()",
    component: () => import("/src/code/pages/author/[1].vue")
  },
  {
    name: "authorization",
    path: "/authorization",
    meta: indexn3dxxNC7fFMeta || {},
    component: () => import("/src/code/pages/authorization/index.vue")
  },
  {
    name: "communities-id-subId",
    path: "/communities/:id()/:subId()",
    component: () => import("/src/code/pages/communities/[id]/[subId].vue")
  },
  {
    name: "communities-id-control",
    path: "/communities/:id()/control",
    component: () => import("/src/code/pages/communities/[id]/control.vue")
  },
  {
    name: "communities-id",
    path: "/communities/:id()",
    component: () => import("/src/code/pages/communities/[id]/index.vue")
  },
  {
    name: "communities",
    path: "/communities",
    component: () => import("/src/code/pages/communities/index.vue")
  },
  {
    name: "course",
    path: "/course",
    component: () => import("/src/code/pages/course/index.vue")
  },
  {
    name: "footer",
    path: "/footer",
    component: () => import("/src/code/pages/footer.vue"),
    children: [
  {
    name: "footer-route",
    path: ":route()",
    component: () => import("/src/code/pages/footer/[route].vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/src/code/pages/index.vue")
  },
  {
    name: "messenger",
    path: "/messenger",
    meta: indexAR5TEoFhioMeta || {},
    component: () => import("/src/code/pages/messenger/index.vue")
  },
  {
    name: "mobile",
    path: "/mobile",
    meta: mobile89AiqugFuIMeta || {},
    component: () => import("/src/code/pages/mobile.vue")
  },
  {
    name: "my-communities-create",
    path: "/my-communities/create",
    component: () => import("/src/code/pages/my-communities/create.vue")
  },
  {
    name: "my-communities",
    path: "/my-communities",
    component: () => import("/src/code/pages/my-communities/index.vue")
  },
  {
    name: "oauth-callback",
    path: "/oauth/callback",
    meta: callbackgXQDxouLnbMeta || {},
    component: () => import("/src/code/pages/oauth/callback.vue")
  },
  {
    name: "product",
    path: "/product",
    component: () => import("/src/code/pages/product/index.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: profileHHQTPbZKI3Meta || {},
    component: () => import("/src/code/pages/profile.vue"),
    children: [
  {
    name: "profile-cart",
    path: "cart",
    component: () => import("/src/code/pages/profile/cart.vue")
  },
  {
    name: "profile-comments",
    path: "comments",
    component: () => import("/src/code/pages/profile/comments.vue")
  },
  {
    name: "profile-courses",
    path: "courses",
    component: () => import("/src/code/pages/profile/courses.vue")
  },
  {
    name: "profile-dashboard",
    path: "dashboard",
    meta: dashboardsVX1e6mn84Meta || {},
    component: () => import("/src/code/pages/profile/dashboard.vue")
  },
  {
    name: "profile-favorites",
    path: "favorites",
    component: () => import("/src/code/pages/profile/favorites.vue")
  },
  {
    name: "profile-notifications",
    path: "notifications",
    component: () => import("/src/code/pages/profile/notifications.vue")
  },
  {
    name: "profile-orders",
    path: "orders",
    component: () => import("/src/code/pages/profile/orders.vue")
  }
]
  },
  {
    name: "search",
    path: "/search",
    meta: indexRO7nz4gVqZMeta || {},
    component: () => import("/src/code/pages/search/index.vue")
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/src/code/pages/test.vue")
  },
  {
    name: "users-username",
    path: "/users/:username()",
    component: () => import("/src/code/pages/users/[username].vue")
  },
  {
    name: "welcome",
    path: "/welcome",
    component: () => import("/src/code/pages/welcome/index.vue")
  }
]