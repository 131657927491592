import revive_payload_client_4sVQNw7RlN from "/src/code/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/src/code/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/src/code/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/src/code/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/src/code/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/src/code/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/src/code/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/src/code/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/src/code/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/src/code/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_6wEQMY3tee from "/src/code/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_client_Fdvg3o8568 from "/src/code/node_modules/@nuxtjs/web-vitals/dist/runtime/plugin.client.mjs";
import plugin_client_4F1kZh3YAB from "/src/code/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import plugin_ghbUAjaD3n from "/src/code/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_eTVJQYlCmx from "/src/code/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import plugin_I4dbrL2rYz from "/src/code/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import apolloAuth_IWNQtbMXgC from "/src/code/plugins/apolloAuth.ts";
import ckeditor_client_AuHlmi9QPC from "/src/code/plugins/ckeditor.client.ts";
import click_outside_D2NiLGp7dJ from "/src/code/plugins/click-outside.ts";
import error_ldt3PQauZ0 from "/src/code/plugins/error.ts";
import flag_emoji_polyfill_client_OcKK0ONpCG from "/src/code/plugins/flag-emoji-polyfill.client.ts";
import initState_lBUKmr5TjR from "/src/code/plugins/initState.ts";
import modal_UEFnUXzgTX from "/src/code/plugins/modal.ts";
import position_popup_WVwTRsEwWy from "/src/code/plugins/position-popup.ts";
import vue_mask_Sy5Q0VdlQN from "/src/code/plugins/vue-mask.ts";
import vue_virtual_scrolling_lNdpaFlHmk from "/src/code/plugins/vue-virtual-scrolling.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  nuxt_plugin_6wEQMY3tee,
  plugin_client_Fdvg3o8568,
  plugin_client_4F1kZh3YAB,
  plugin_ghbUAjaD3n,
  plugin_eTVJQYlCmx,
  plugin_I4dbrL2rYz,
  apolloAuth_IWNQtbMXgC,
  ckeditor_client_AuHlmi9QPC,
  click_outside_D2NiLGp7dJ,
  error_ldt3PQauZ0,
  flag_emoji_polyfill_client_OcKK0ONpCG,
  initState_lBUKmr5TjR,
  modal_UEFnUXzgTX,
  position_popup_WVwTRsEwWy,
  vue_mask_Sy5Q0VdlQN,
  vue_virtual_scrolling_lNdpaFlHmk
]