import * as pkg from 'vue-the-mask';
const { mask } = pkg;

export default defineNuxtPlugin((nuxtApp) => {
  
  nuxtApp.vueApp.directive('mask', (el: HTMLElement, binding: any) => {
    const config = binding.value
    if (config === false) {
      return
    }

    mask(el, binding)
  })
}) 
