import { defineStore } from "pinia";
import { useGraphQLQuery } from '@/composables/useApolloClient'
import type { SubCategoryData } from "./categories"

export interface Article {
  id: string | number;
  title: string;
  content?: string;
  date?: Date;
  readingTime?: number;
  category?: ArticlesCategories;
  categoryId?: string;
  categoryIcon?: string;
  subCategory?: SubCategoryData;
  subCategoryId?: string;
  subCategoryIcon?: string;
  authors?: Author[];
  author?: Author;
  type?: 'content' | 'audio_podcast' | 'video' | 'article';
  podcastSrc?: string;
  podcastId?: string;
  saveCount?: number;
  bookmarked?: boolean;
  bookmarkId?: string;
  liked?: boolean;
  createdAt?: Date;
  previewImg?: string;
  previewImgWidth?: string;
  previewImgHeight?: string;
  previewSubtitle?: string;
  columns?: number;
  mainImage?: string;
  discussonId?: string;
  categoryImg: string;
  authorId?: string
}

export interface ArticlePreview extends Article {
  preloadedImage: {
    width: number;
    height: number;
  }
}

type Hint = {
  value: string
}

type ArticleField<T> = Partial<Record<keyof Article, T>>


interface ArticlesState {
  articles: Article[];
  hints: Hint[];
  articlesPreviews: ArticlePreview[];
  searchResults: ArticlePreview[];
  isFetching: boolean;
  isFetchArticleBySubCategoryDone: boolean,
  isFetchArticleByIdDone: boolean,
  isSearchArticleDone: boolean,
  fetchedFields: {
    fetchArticleBySubCategory: ArticleField<boolean>,
    fetchArticleById: ArticleField<boolean>,
    searchArticle: ArticleField<boolean>,
  }
}

export type Author = {
  userId?: string;
  userName: string;
  isMain?: boolean;
  roleName?: string;
  avatar?: string;
  name?: string;
  surname?: string;
}

type AuthorResponse = {
  user?: {
    data: {
      id: string;
      attributes: {
        username: string
        avatar: {
          data: {
            attributes: {
              url: string
            }
          }
        }
      }
    }
  }
  is_main: boolean
  role_name?: string
}

type ArticleResponseAttributes = {
  title: string;
  content: string;
  reading_time?: number;
  discussion_id?: string;
  category?: {
    data: {
      id: string;
      attributes: {
        label: string;
        image: {
          data: {
            attributes: {
              url: string;
            }
          }
        }
      }
    }
  }
  sub_category?: {
    data: {
      id: string;
      attributes: {
        label: string;
        icon: {
          data: {
            attributes: {
              name: string;
            }
          }
        }
      }
    }
  }
  authors?: AuthorResponse[]
  author: {
    data: {
      id: string
      attributes: {
        username: string
        avatar: {
          data: {
            attributes: {
              url: string
            }
          }
        }
      }
    }
  }
  type?: string,
  podcast_id?: {
    data: {
      id: string
      attributes: {
        sources: [
          {
            audio: {
              data: {
                attributes: {
                  url: string;
                }
              }
            }
          }
        ]
      }
    }
  }
  publishedAt?: Date
  preview_image?: {
    data: {
      attributes: {
        url: string
        width: string
        height: string
      }
    }
  }
  preview_subtitle?: string
  preview_columns?: number
}

type ArticleResponse = {
  id: string;
  attributes: ArticleResponseAttributes
}

type ArticlesResponse = {
  articles: {
    data: ArticleResponse[]
  }
}

type ArticlesCategories = {
  attributes: {
    label: string
    image?: {
      data: {
        attributes: {
          url: string
        }
      }
    }
    sub_categories?: {
      data: {
        id: string
      }
    }
  }
}

const articlesPreviewsFragment = gql`
  fragment ArticlesPreviewsFields on ArticleEntityResponseCollection {
    data {
      id
      attributes {
        title
        content
        category {
          data {
            attributes {
              label
            }
          }
        }
        publishedAt
        preview_image {
          data {
            attributes {
              url
              height
              width
            }
          }
        }
        preview_subtitle
        preview_columns
      }
    }
  }
`;

export const useArticlesStore = defineStore('articles', {
  state: (): ArticlesState => ({
    articles: [],
    hints: [],
    articlesPreviews: [],
    searchResults: [],
    isFetching: false,
    isFetchArticleBySubCategoryDone: false,
    isFetchArticleByIdDone: false,
    isSearchArticleDone: false,
    fetchedFields: {
      fetchArticleBySubCategory: {},
      fetchArticleById: {},
      searchArticle: {}
    },
  }),
  getters: {
    getArticleByID: (state: ArticlesState) => (id: string | string[]) => {
      return state.articles.find((article) => article.id === id)
    },
    getArticleBySubCategory: (state: ArticlesState) => (id: string | string[], subId: string | string[]) => {
      return state.articles.filter((article) => article.categoryId === id && article.subCategoryId === subId)
    },
    getArticlesPreviewsBySubCategory: (state: ArticlesState) => (id: string | string[], subId: string | string[]) => {
      return state.articlesPreviews.filter((article) => article.categoryId === id && article.subCategoryId === subId)
    },
    getArticlesPreviewsByCategory: (state: ArticlesState) => (id: string | string[]) => {
      return state.articlesPreviews.filter((article) => article.categoryId === id)
    },
    getArticlePreviewByID: (state: ArticlesState) => (id: string | string[]) => {
      return state.articlesPreviews.find((article) => article.id === id)
    },
    getFavoriteArticles: (state: ArticlesState) => {
      const bookmarkStore = useBookmarksStore()

      return state.articlesPreviews.filter(article => bookmarkStore.bookmarks.some(bookmark => bookmark.articleId === article.id))
    },
    getArticlesPreviewsByAuthor: (state: ArticlesState) => (id: string | string[]) => {
      return state.articlesPreviews.filter((article) => article.authorId === id)
    },
  },
  actions: {
    async fetchArticleBySubCategory(id: string | string[] | number, subId: string | string[] | number) {
      const bookmarkStore = useBookmarksStore()

      const { fetch } = useGraphQLQuery<ArticlesResponse>(gql`
        query ($id: ID!, $subId: ID!) {
          articles(filters: { 
            category: { 
              id: { 
                eq: $id 
              }
            } 
            sub_category: {
              id: {
                eq: $subId
              }
            }
          }) {
            data {
              id
              attributes {
                title
                preview_subtitle
                preview_image {
                  data {
                    attributes {
                      url
                      width
                      height
                    }
                  }
                }
                discussion_id
                publishedAt
                preview_columns
                category {
                  data {
                    id
                    attributes {
                      label
                      image {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
                sub_category{
                  data {
                    id
                  }
                }
                publishedAt
                preview_image {
                  data {
                    attributes {
                      url
                      height
                      width
                    }
                  }
                }
                preview_subtitle
                preview_columns
                author {
                  data {
                    id 
                    attributes {
                      username
                      name
                      surname
                      avatar {
                        data {
                          attributes {
                            url
                          }
                        } 
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `, { id: id, subId: subId })

      const data = await fetch()

      if (data && data.articles) {
        const articles: Article[] = data.articles.data.map((article: ArticleResponse) => {
          return {
            id: article.id,
            title: article.attributes.title,
            category: article.attributes.category?.data.attributes.label,
            categoryId: article.attributes.category?.data.id,
            categoryImg: article.attributes.category?.data.attributes.image?.data?.attributes?.url,
            subCategoryId: article.attributes.sub_category?.data.id,
            createdAt: article.attributes.publishedAt,
            previewImg: article.attributes.preview_image?.data.attributes.url,
            previewSubtitle: article.attributes.preview_subtitle,
            columns: article.attributes.preview_columns,
            bookmarked: bookmarkStore.bookmarks.some(bookmark => bookmark.articleId === article.id),
            bookmarkId: bookmarkStore.bookmarks.find(bookmark => {
              return bookmark.articleId === article.id
            })?.id,
            type: 'article',
            preloadedImage: {
              width: article.attributes.preview_image?.data.attributes.width,
              height: article.attributes.preview_image?.data.attributes.height
            },
            discussionId: article.attributes.discussion_id,
            author: { 
              name: article.attributes.author?.data?.attributes.name,
              surname: article.attributes.author?.data?.attributes.surname,
              userId: article.attributes.author?.data?.id,
              userName: article.attributes.author?.data?.attributes.username,
              avatar: article.attributes.author?.data?.attributes.avatar?.data?.attributes.url
            }
          }
        })

        articles.forEach(article => {
          if (!this.articlesPreviews.some(a => a.id === article.id)) {
            this.articlesPreviews.push(article);
          }
        });

        this.isFetching = false
        this.isFetchArticleBySubCategoryDone = true

        return articles
      }
    },
    async fetchArticleByCategory(id: string | string[]) {
      const bookmarkStore = useBookmarksStore()

      const { fetch } = useGraphQLQuery(gql`
        query ($id: ID!) {
          articles(filters: { 
            category: { 
              id: { 
                eq: $id 
              }
            } 
          }) {
            data {
              id
              attributes {
                title
                preview_subtitle
                preview_image {
                  data {
                    attributes {
                      url
                      width
                      height
                    }
                  }
                }
                publishedAt
                preview_columns
                discussion_id
                category {
                  data {
                    id
                    attributes {
                      label
                      image {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
                sub_category{
                  data {
                    id
                  }
                }
                publishedAt
                preview_image {
                  data {
                    attributes {
                      url
                      height
                      width
                    }
                  }
                }
                preview_subtitle
                preview_columns
                author {
                  data {
                    id 
                    attributes {
                      name
                      surname
                      username
                      avatar {
                        data {
                          attributes {
                            url
                          }
                        } 
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `, { id: id })

      const data = await fetch()

      if (data && data.articles) {
        const articles: Article[] = data.articles.data.map((article: ArticleResponse) => {
          return {
            id: article.id,
            title: article.attributes.title,
            category: article.attributes.category?.data.attributes.label,
            categoryId: article.attributes.category?.data.id,
            categoryImg: article.attributes.category?.data.attributes.image?.data?.attributes?.url,
            subCategoryId: article.attributes.sub_category?.data.id,
            createdAt: article.attributes.publishedAt,
            previewImg: article.attributes.preview_image?.data.attributes.url,
            previewSubtitle: article.attributes.preview_subtitle,
            columns: article.attributes.preview_columns,
            bookmarked: bookmarkStore.bookmarks.some(bookmark => bookmark.articleId === article.id),
            bookmarkId: bookmarkStore.bookmarks.find(bookmark => {
              return bookmark.articleId === article.id
            })?.id,
            type: 'article',
            preloadedImage: {
              width: article.attributes.preview_image?.data.attributes.width,
              height: article.attributes.preview_image?.data.attributes.height
            },
            discussionId: article.attributes.discussion_id,
            author: {
              name: article.attributes.author?.data?.attributes.name,
              surname: article.attributes.author?.data?.attributes.surname,
              userId: article.attributes.author?.data?.id,
              userName: article.attributes.author?.data?.attributes.username,
              avatar: article.attributes.author?.data?.attributes.avatar?.data?.attributes.url
            }
          } as Article
        })

        articles.forEach(article => {
          if (!this.articlesPreviews.some(a => a.id === article.id)) {
            this.articlesPreviews.push(article);
          }
        });

        this.isFetching = false
        this.isFetchArticleBySubCategoryDone = true

        return articles
      }
    },
    async fetchArticleById(articleId: string | string[]) {
      this.isFetching = true

      const bookmarkStore = useBookmarksStore()

      const { fetch } = useGraphQLQuery<ArticlesResponse>(gql`
        query ($id: ID!) {
          article (id: $id){
            data {
              id
              attributes {
                preview_subtitle
                preview_image {
                  data {
                    attributes {
                      url
                      width
                      height
                    }
                  }
                }
                main_image {
                  data{
                    attributes{
                      url
                    }
                  }
                }
                discussion_id
                title
                publishedAt
                content
                reading_time
                category {
                  data {
                    id
                    attributes {
                      label
                      image {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                      sub_categories {
                        data {
                          id
                        }
                      }
                    }
                  }
                }
                sub_category {
                  data {
                    id
                    attributes {
                      label
                      icon {
                        data {
                          attributes {
                            name
                          }
                        }
                      }
                    }
                  }
                }
                authors {
                  user {
                    data {
                      id 
                      attributes {
                        username
                        avatar {
                         data {
                          attributes {
                            url
                          }
                         } 
                        }
                      }
                    }
                  }
                  is_main
                  role_name
                }
                type 
                podcast_id {
                  data {
                    id 
                    attributes {
                      sources {
                        audio {
                          data {
                            attributes {
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `, { id: articleId })
      const data = await fetch()

      if (data && data.article) {
        const responseArticle = data.article.data

        let article: Article = {
          id: responseArticle.id,
          mainImage: responseArticle.attributes.main_image.data.attributes.url,
          title: responseArticle.attributes.title,
          date: responseArticle.attributes.publishedAt,
          content: responseArticle.attributes.content,
          readingTime: responseArticle.attributes.reading_time,
          category: responseArticle.attributes.category?.data,
          categoryImg: responseArticle.attributes.category?.data?.attributes?.image?.data?.attributes?.url,
          subCategory: responseArticle.attributes.sub_category?.data,
          subCategoryIcon: responseArticle.attributes.sub_category?.data?.attributes?.icon?.data?.attributes?.name,
          discussionId: responseArticle.attributes.discussion_id,
          previewImg: responseArticle.attributes.preview_image?.data.attributes.url,
          previewImgWidth: responseArticle.attributes.preview_image?.data.attributes.width,
          previewImgHeight: responseArticle.attributes.preview_image?.data.attributes.height,
          previewSubtitle: responseArticle.attributes.preview_subtitle,
          authors: responseArticle.attributes.authors?.map((author: AuthorResponse) => {
            return {
              userId: author.user?.data?.id,
              userName: author.user?.data?.attributes?.username,
              avatar: author.user?.data?.attributes?.avatar?.data?.attributes?.url,
              isMain: author.is_main,
              roleName: author.role_name
            }
          }),
          type: responseArticle.attributes.type,
          bookmarked: bookmarkStore.bookmarks.some(bookmark => {
            return bookmark.articleId === responseArticle.id
          }),
          bookmarkId: bookmarkStore.bookmarks.find(bookmark => {
            return bookmark.articleId === responseArticle.id
          })?.id as string
        }

        if (responseArticle.attributes.podcast_id) {
          article.podcastSrc = responseArticle.attributes.podcast_id.data?.attributes?.sources[0]?.audio?.data?.attributes?.url;
          article.podcastId = responseArticle.attributes.podcast_id.data?.id;
        }

        this.articles.push(article)
        this.isFetching = false
        this.isFetchArticleByIdDone = true
      }
    },
    async fetchArticlePreviewById(articleId: string | string[]) {
      this.isFetching = true

      const bookmarkStore = useBookmarksStore()

      const { fetch } = useGraphQLQuery<ArticlesResponse>(gql`
        query ($id: ID!) {
          article (id: $id) {
            data {
              id
              attributes {
                title
                preview_subtitle
                preview_image {
                  data {
                    attributes {
                      url
                      width
                      height
                    }
                  }
                }
                publishedAt
                preview_columns
                category {
                  data {
                    id
                    attributes {
                      label
                      image {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
                sub_category{
                  data {
                    id
                  }
                }
                publishedAt
                preview_image {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                preview_subtitle
                preview_columns
                author {
                  data {
                    id 
                    attributes {
                      username
                      name
                      surname
                      avatar {
                        data {
                          attributes {
                            url
                          }
                        } 
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `, { id: articleId })

      const data = await fetch()

      if (data && data.article) {
        const article = data.article.data

        let articlePreview: ArticlePreview = {
          id: article.id,
          title: article.attributes.title,
          category: article.attributes.category?.data.attributes.label,
          categoryId: article.attributes.category?.data.id,
          categoryImg: article.attributes.category?.data.attributes.image?.data?.attributes?.url,
          subCategoryId: article.attributes.sub_category?.data.id,
          createdAt: article.attributes.publishedAt,
          previewImg: article.attributes.preview_image?.data.attributes.url,
          previewSubtitle: article.attributes.preview_subtitle,
          columns: article.attributes.preview_columns,
          bookmarked: bookmarkStore.bookmarks.some(bookmark => bookmark.articleId === article.id),
          bookmarkId: bookmarkStore.bookmarks.find(bookmark => {
            return bookmark.articleId === article.id
          })?.id as string,
          type: 'article',
          preloadedImage: {
            width: article.attributes.preview_image?.data.attributes.width,
            height: article.attributes.preview_image?.data.attributes.height
          },
          author: {
            name: article.attributes.author?.data?.attributes.name,
            surname: article.attributes.author?.data?.attributes.surname,
            userId: article.attributes.author?.data?.id,
            userName: article.attributes.author?.data?.attributes.username,
            avatar: article.attributes.author?.data?.attributes.avatar?.data?.attributes.url
          }
        }

        this.isFetching = false
        this.articlesPreviews.push(articlePreview)
      }
    },
    async searchArticle(searchString: string, categoryIds?: string[]) {
      this.isFetching = true

      const bookmarkStore = useBookmarksStore()

      const { fetch } = useGraphQLQuery<ArticlesResponse>(gql`
        query ($searchString: String!, $categoryIds: [ID]) {
          articles(filters: { 
            and: [
              { category: { id: { in: $categoryIds } }},
              { or: [
                { title: { containsi: $searchString }},
                { content: { containsi: $searchString }},
                { preview_subtitle: { containsi: $searchString }}
              ]}
            ]
          }) {
            data {
              id
              attributes {
                title
                content 
                category {
                  data {
                    id
                    attributes {
                      label
                      image {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
                sub_category{
                  data {
                    id
                  }
                }
                publishedAt
                preview_image {
                  data {
                    attributes {
                      height
                      width
                      url
                    }
                  }
                }
                preview_subtitle
                preview_columns
                }
              }
            }
          }
      `, { searchString: searchString, categoryIds: categoryIds }, true)

      const data = await fetch()
      const searchStore = useSearchStore()
      const { status } = useAuth()
      const { bookmarks } = storeToRefs(bookmarkStore)

      if (data && data.articles) {
        const existingSearchIndex = searchStore.searchHistory.findIndex(history => history.value === searchString)

        if (data.articles.data.length && (status.value === 'authenticated')) {
          if (existingSearchIndex !== -1) {
            const existingSearchItem = searchStore.searchHistory[existingSearchIndex]
            const existingSearchId = existingSearchItem.id
            await searchStore.deleteSearchHistory(existingSearchId)
          } 
          await searchStore.createSearchHistory(searchString)
        }

        const convertHtml = (html: string) => {
          const tmp = document.createElement("div")
          let formalizedHtml = html

          //add space before every tag close, so that words don't stick together
          formalizedHtml = formalizedHtml.replace(/<\/[^>]*>/g, ' $&')

          tmp.innerHTML = formalizedHtml
          return tmp.textContent || tmp.innerText || ""
        };

        data.articles.data.forEach((article: ArticleResponse) => {
          const content = convertHtml(article.attributes.content)
          const title = convertHtml(article.attributes.title)
          const preview = convertHtml(article.attributes.preview_subtitle as string)

          const PREVIEW_LENGTH = 40
          const MATCHED_STYLES = 'background-color: #FEA383; border-radius: 15px; padding-left: 4px; padding-right: 4px;'

          const matchedTitle = title.toLowerCase().includes(searchString.toLowerCase())
          const matchedContent = content.toLowerCase().includes(searchString.toLowerCase())
          const matchedPreview = preview?.toLowerCase().includes(searchString.toLowerCase())
          const isMatchedArticle = matchedTitle || matchedContent || matchedPreview

          let previewText: string | undefined
          let markedTitle = ''

          const contentWords = content.split(/\s+/)
          const titleWords = title.split(/\s+/)
          const previewWords = preview?.split(/\s+/)

          const highlightMatchedWords = (wordsArray: string[], isContent = false) => {
            let foundMatch = false
            const matchedRegex = new RegExp(searchString, 'i')
          
            for (let i = 0; i < wordsArray.length; i++) {
              let word = wordsArray[i].trim()
          
              const isAlreadyHighlighted = word.includes(MATCHED_STYLES)
          
              if (!isAlreadyHighlighted && matchedRegex.test(word)) {
                word = word.replace(/\s+/g, '')
                const punctuationIndex = word.search(/[.,!?;:*]/)

                if (punctuationIndex !== -1) {
                  word = word.slice(0, punctuationIndex);
                }

                wordsArray[i] = `<span style="${MATCHED_STYLES}">${word.trim()}</span>`
                
                if (!foundMatch) {
                  previewText = wordsArray.slice(i, i + PREVIEW_LENGTH).join(' ')
                  if (isContent && wordsArray.length > i + PREVIEW_LENGTH) {
                    previewText += '...'
                  }
                  foundMatch = true;
                }
              }
            }
            
            return wordsArray.join(' ')
          };

          const addMatchedStyles = (wordsArray: string[], isTitle?: boolean, isContent = false) => {
            const highlightedText = highlightMatchedWords(wordsArray, isContent)
            if (isTitle) {
              markedTitle = highlightedText
            }
          };

          if (matchedTitle) {
            addMatchedStyles(titleWords, true)
          }
        
          if (matchedPreview) {
            addMatchedStyles(previewWords)
            previewText = highlightMatchedWords(previewWords)
          } else if (matchedContent) {
            addMatchedStyles(contentWords, false, true)
            if (!previewText) {
              previewText = '...' + highlightMatchedWords(contentWords, true)
            }
          }

          if (isMatchedArticle) {
            this.searchResults.push({
              id: article.id,
              title: matchedTitle ? markedTitle : title,
              category: article.attributes.category?.data.attributes.label,
              categoryId: article.attributes.category?.data.id,
              categoryImg: article.attributes.category?.data.attributes.image?.data?.attributes?.url as string,
              subCategoryId: article.attributes.sub_category?.data.id,
              createdAt: article.attributes.publishedAt,
              previewImg: article.attributes.preview_image?.data.attributes.url,
              previewSubtitle: (matchedPreview || matchedContent) ? previewText : preview,
              columns: article.attributes.preview_columns,
              bookmarked: bookmarks.value.some(bookmark => {
              return bookmark.articleId === article.id
              }),
              bookmarkId: bookmarks.value.find(bookmark => {
              return bookmark.articleId === article.id
              })?.id as string,
              type: 'article',
              preloadedImage: {
                width: Number(article.attributes.preview_image?.data.attributes.width),
                height: Number(article.attributes.preview_image?.data.attributes.height)
              }
            })
          }
        })

        this.isFetching = false
        this.isSearchArticleDone = true
      }
    },
    updateBookmark(items: Article[], articleId: string, bookmarkId: string | undefined, isBookmarked: boolean): void {
      const item = items.find(item => item.id === articleId);
      if (item) {
        item.bookmarked = isBookmarked;
        if (bookmarkId) {
          item.bookmarkId = isBookmarked ? bookmarkId : undefined;
        }
      }
    },
    addBookmark(this: { articles: Article[]; articlesPreviews: Article[] }, articleId: string, bookmarkId?: string): void {
      const store = useArticlesStore()
      store.updateBookmark(this.articles, articleId, bookmarkId, true);
      store.updateBookmark(this.articlesPreviews, articleId, bookmarkId, true);
      store.updateBookmark(store.searchResults, articleId, bookmarkId, true);
    },
    removeBookmark(this: { articles: Article[]; articlesPreviews: Article[] }, articleId: string): void {
      const store = useArticlesStore()
      store.updateBookmark(this.articles, articleId, undefined, false);
      store.updateBookmark(this.articlesPreviews, articleId, undefined, false);
      store.updateBookmark(store.searchResults, articleId, undefined, false);
    },
    async searchHints(searchString: string, entityTypes: string[], categoryIds?: string[]) {
      this.isFetching = true

      const query = gql`
        query ($searchString: String!, $categoryIds: [ID]) {
          ${entityTypes.map(entityType => `
            ${entityType}(filters: { 
              and: [
                { category: { id: { in: $categoryIds } }},
                { or: [
                  { title: { containsi: $searchString }},
                ]}
              ]
            }) {
              ...ArticlesPreviewsFields
            }
          `).join('')}
        }
        ${articlesPreviewsFragment}`

      const { fetch } = useGraphQLQuery<Response>(query, { searchString: searchString, categoryIds: categoryIds }, true);
    
      const data = await fetch()
      
      if (data && data.articles) {
        this.hints = []
        this.articles = []
    
        data.articles.data.forEach((article: ArticleResponse) => {
          if (article.attributes.title.toLowerCase().includes(searchString.toLowerCase())) {
            const title = article.attributes.title;
            const boldTitle = title.replace(new RegExp(searchString, 'gi'), (matched) => `<strong>${matched}</strong>`);
            this.hints.push({ value: boldTitle })
          }
        })
    
        this.isFetching = false
      }
    },
    resetArtilesPreview() {
      this.searchResults = []
    },
    resetHints() {
      this.hints = []
    },
    toggleBookmark(id: string | number) {
      const article = this.articles.find(article => article.id === id)
      if (!article) return
      article.bookmarked = !article.bookmarked
    },
    toggleLike(id: string | number) {
      const article = this.articles.find(article => article.id === id)
      if (!article) return

      article.liked = !article.liked

      if (article.liked && article.saveCount) {
        article.saveCount++
      } else if (article.saveCount) {
        article.saveCount--
      }
    },
    async fetchArticles(
      fields: string[], 
      id?: string | string[], 
      subId?: string | string[], 
      searchString?: string, 
      categoryIds?: string[]
    ) {
      const toCamelCase = (str: string): string => {
        return str.replace(/([-_][a-z])/g, (group) => group.toUpperCase()
          .replace('_', ''))
      }
      
      const toSnakeCase = (str: string): string => {
        return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
      }

      let fieldsToFetch = fields.filter(field => {
        const fieldName = toSnakeCase(field) as keyof Article

        !this.fetchedFields.fetchArticleBySubCategory[fieldName] &&
        !this.fetchedFields.fetchArticleById[fieldName] &&
        !this.fetchedFields.searchArticle[fieldName]
      })

      fieldsToFetch.forEach(field => {
        // if (article.hasOwnProperty(toCamelCase(field))) {
          // result[toCamelCase(field)] = article.attributes[field]
          const fieldName = toSnakeCase(field) as keyof Article
          this.fetchedFields.fetchArticleBySubCategory[fieldName] = true
          this.fetchedFields.fetchArticleById[fieldName] = true
          this.fetchedFields.searchArticle[fieldName] = true
        // }
      })

      if (this.articles.length > 0) {
        fieldsToFetch = fieldsToFetch.filter(field => 
          !this.articles[0].hasOwnProperty(toCamelCase(field))
        )
      }

      if (fieldsToFetch.length === 0) {
        return
      }

      this.isFetching = true

      let fieldsQuery = fieldsToFetch.map(field => `attributes { ${field} }`).join('\n')

      const { fetch } = useGraphQLQuery(gql`
        query ($id: ID!, $subId: ID!, $searchString: String!, $categoryIds: [ID]) {
          articles(filters: { 
            category: { 
              id: { 
                eq: $id 
              }
            } 
            sub_category: {
              id: {
                eq: $subId
              }
            }
            and: [
              { category: { id: { in: $categoryIds } }},
              { or: [
                { title: { containsi: $searchString }},
                { content: { containsi: $searchString }},
              ]}
            ]
          }) {
            data {
              id
              ${fieldsQuery}
            }
          }
        }
      `, { id: id, subId: subId, searchString: searchString, categoryIds: categoryIds })

      const data = await fetch()

      if (data && data.articles) {
        this.articles = data.articles.data.map((article: ArticleResponse) => {
          let result: any = { id: article.id }

          fieldsToFetch.forEach(field  => {
            if (article.hasOwnProperty(toCamelCase(field))) {
              const fieldName = toCamelCase(field) as keyof Article
              result[fieldName] = article.attributes[field as keyof ArticleResponseAttributes]
              this.fetchedFields.fetchArticleBySubCategory[fieldName] = true
              this.fetchedFields.fetchArticleById[fieldName] = true
              this.fetchedFields.searchArticle[fieldName] = true
            }
          })

          return result
        })
        this.isFetching = false
      }
    },

    async fetchArticleBySubAuthor(id: string | string[] | number) {
      const bookmarkStore = useBookmarksStore()

      const { fetch } = useGraphQLQuery(gql`
        query ($id: ID!) {
          articles(filters: { 
            author: { 
              id: { 
                eq: $id 
              }
            } 
          }) {
            data {
              id
              attributes {
                author {
                  data {
                    id 
                    attributes {
                      username
                      name
                      surname
                      avatar {
                        data {
                          attributes {
                            url
                          }
                        } 
                      }
                    }
                  }
                }
                title
                preview_subtitle
                preview_image {
                  data {
                    attributes {
                      url
                      width
                      height
                    }
                  }
                }
                discussion_id
                publishedAt
                preview_columns
                category {
                  data {
                    id
                    attributes {
                      label
                    }
                  }
                }
                sub_category{
                  data {
                    id
                  }
                }
                publishedAt
                preview_image {
                  data {
                    attributes {
                      url
                      height
                      width
                    }
                  }
                }
                preview_subtitle
                preview_columns
              }
            }
          }
        }
      `, { id: id })

      const data = await fetch()

      if (data && data.articles) {
        const responseArticles = Array.isArray(data.articles.data) ? data.articles.data : [data.articles.data];
        const articles = data.articles.data.map((article: ArticleResponse) => {
          return {
            id: article.id,
            title: article.attributes.title,
            category: article.attributes.category?.data.attributes.label,
            categoryId: article.attributes.category?.data.id,
            subCategoryId: article.attributes.sub_category?.data.id,
            createdAt: article.attributes.publishedAt,
            previewImg: article.attributes.preview_image?.data.attributes.url,
            previewSubtitle: article.attributes.preview_subtitle,
            columns: article.attributes.preview_columns,
            bookmarked: bookmarkStore.bookmarks.some(bookmark => bookmark.articleId === article.id),
            bookmarkId: bookmarkStore.bookmarks.find(bookmark => {
              return bookmark.articleId === article.id
            })?.id,
            type: 'article',
            preloadedImage: {
              width: article.attributes.preview_image?.data.attributes.width,
              height: article.attributes.preview_image?.data.attributes.height
            },
            discussionId: article.attributes.discussion_id,
            authorId: article.attributes.author?.data.id,
            author: { 
              userName: article.attributes.author?.data?.attributes.username,
              avatar: article.attributes.author?.data?.attributes.avatar?.data?.attributes.url
            }
          }
        })

        articles.forEach(article => {
          if (!this.articlesPreviews.some(a => a.id === article.id)) {
            this.articlesPreviews.push(article);
          }
        });

        this.isFetching = false

        return articles
      }
    },
  }
})
