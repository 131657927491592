import { defineStore } from "pinia"; 
import { useGraphQLQuery } from '@/composables/useApolloClient'
import { ENUM_USERPICKUP_COUNTRY, ENUM_USERPICKUP_TYPE } from '@/interfaces/enums'

const removeFileFromStorage = (id: string) => {
  const mutation = gql`
    mutation ($id: ID!) {
      removeFile(id: $id) {
        data {
          id
        }
      }
    }
  `
  const variables = { id }

  return new Promise<void>((resolve, reject) => {
    const request = useMutation(mutation, { variables })
    request.onDone(() => {
      resolve()
    })
    request.onError((error) => {
      console.error(error)
      reject(error)
    })

    request.mutate()
  })
}

export interface UserPickupResponse {
  id: string,
  attributes: {
    street: string;
    flat_number: number;
    house_number: string;
    type: string;
    city: string;
    delivery_coordinates: string;
    porch_number: number;
    floor_number: number;
    intercom_number: string;
    courier_comment: string;
  }
}

export interface PaymentMethodResponse {
  attributes: {
    bank_owner: string;
    card_number: number | string;
  }
}

export interface UserPickupInput {
  city?: string
  street?: string
  house_number?: string
  flat_number?: number
  full_name?: string
  pickup_date?: Date
  store_date?: Date
  delivery_price?: number
  phone_number?: bigint
  country?: ENUM_USERPICKUP_COUNTRY
  type?: ENUM_USERPICKUP_TYPE
  calls_declined?: boolean
  user?: number
  order_id?: number
  delivery_coordinates?: string
  publishedAt?: Date
  id?: string
  porch_number?: number
  floor_number?: number
  intercom_number?: string
  courier_comment?: string
}

export interface Me {
  phone?: string
  email?: string
  name?: string
  surname?: string
  id?: string | number
  city?: string
  avatar?: string
  avatarId?: string
  roles?: string[]
  username?: string
  registrationComplete?: boolean
  dateOfBirth?: string
  banner_image?: string
  phoneVerified?: boolean
}

export interface UpdatedField {
  key: keyof Omit<Me, 'roles'>,
  value: string | boolean | number
}

interface MeResponseMe extends Omit<Me, 'banner_image' | 'phone' | 'phoneVerified' | 'avatar'> {
  banner_image: {
    attributes: {
      url: string
    }
  }
  avatar: {
    id: string
    attributes: {
      url: string
    }
  }
  phoneNumber: string
  phoneNumberVerified: boolean
}
interface MeResponse {
  me: MeResponseMe
}

export const useUserStore = defineStore('user', () => {
  const { status, data } = useAuth()
  const token = computed(() => (data.value as any)?.token || '')
  const refreshToken = computed(() => (data.value as any)?.refreshToken || '')
  const expiresIn = computed(() => (data.value as any)?.expiresIn || 0)
  const id = computed(() => (data.value as any)?.id || '')

  const userPickups = ref<UserPickupInput[]>([])
  const paymentMethods = ref([])
  const updatedFields = ref<UpdatedField[]>([])

  const temporarlyPhone = ref('')

  const me = ref<Me>({
    phone: '',
    email: '',
    name: '',
    surname: '',
    id: '',
    city: '',
    avatar: '',
    avatarId: '',
    roles: [],
    username: '',
    registrationComplete: false,
    dateOfBirth: '',
    banner_image: '',
    phoneVerified: false
  })

  const fetchMe = async (noCache = false): Promise<Me> => {
    if (status.value !== 'authenticated') {
      return {
        phone: '',
        email: '',
        name: '',
        surname: '',
        id: '',
        city: '',
        avatar: '',
        avatarId: '',
        roles: [],
        username: '',
        registrationComplete: false,
        dateOfBirth: '',
        banner_image: '',
        phoneVerified: false
      }
    }
    const { fetch } = useGraphQLQuery<MeResponse>(gql`
      query {
        me {
          phoneNumber
          phoneNumberVerified
          email
          name
          surname
          id
          city
          avatar {
            id
            attributes {
              url
            }
          }
          roles
          username
          registrationComplete
          dateOfBirth
          banner_image {
            attributes {
              url
            }
          }
        }
      }
    `, {}, noCache)

    const data = (await fetch()) as MeResponse
    return {
      phone: data.me.phoneNumber,
      email: data.me.email,
      name: data.me.name,
      surname: data.me.surname,
      id: data.me.id,
      city: data.me.city,
      avatar: data.me.avatar?.attributes.url,
      avatarId: data.me.avatar?.id,
      roles: data.me.roles,
      username: data.me.username,
      registrationComplete: data.me.registrationComplete,
      dateOfBirth: data.me.dateOfBirth,
      banner_image: data.me.banner_image?.attributes.url,
      phoneVerified: data.me.phoneNumberVerified,
    }
  }

  const updateMe = async (noCache = false) => {
    me.value = await fetchMe(noCache)
  }

  if (status.value === 'authenticated' && process.client) {
    fetchMe().then((newMe) => {
      me.value = newMe
    })
  }
  
  watch(status, async (newStatus) => {
    if (newStatus === 'authenticated') {
      const newMe = await fetchMe()
      me.value = newMe
    } else {
      me.value = {
        phone: '',
        email: '',
        name: '',
        surname: '',
        id: '',
        city: '',
        avatar: '',
        avatarId: '',
        roles: [],
        username: '',
        registrationComplete: false,
        dateOfBirth: '',
        banner_image: '',
        phoneVerified: false
      }
    }
  })

  const getMeFieldComputedObject = (key: keyof Omit<Me, 'roles'>, defaultValue: string | boolean | number): {
    get: () => string | boolean | number,
    set: (value: string | boolean | number) => void
  } => ({
    get: () => {
      let value
      if (key === 'avatar' || key === 'banner_image') {
        value = me.value[key] || defaultValue
      } else {
        value = updatedFields.value.find((field) => field.key === key)?.value || me.value[key] || defaultValue
      }

      if (value === undefined) {
        return defaultValue
      }

      return value
    },
    set: (value: string | boolean | number) => {
      const updatedFieldIndex = updatedFields.value.findIndex(field => field.key === key)
      if (updatedFieldIndex === -1) {
        updatedFields.value.push({ key, value })
      } else {
        updatedFields.value[updatedFieldIndex].value = value
      }
    }
  })

  const email = computed(getMeFieldComputedObject('email', ''))
  const name = computed(getMeFieldComputedObject('name', ''))
  const surname = computed(getMeFieldComputedObject('surname', ''))
  const city = computed(getMeFieldComputedObject('city', ''))
  const avatar = computed(getMeFieldComputedObject('avatar', ''))
  const avatarId = computed(getMeFieldComputedObject('avatarId', ''))
  const username = computed(getMeFieldComputedObject('username', ''))
  const registrationComplete = computed(getMeFieldComputedObject('registrationComplete', false))
  const dateOfBirth = computed(getMeFieldComputedObject('dateOfBirth', ''))
  const banner_image = computed(getMeFieldComputedObject('banner_image', ''))

  const phone = computed(() => me.value?.phone || '')
  const roles = computed(() => me.value?.roles || [])
  const phoneVerified = computed(() => me.value?.phoneVerified || false)

  const fullInfo = computed(() => {
    return {
      phone: phone.value,
      email: email.value,
      name: name.value,
      surname: surname.value,
      id: id.value,
      city: city.value,
      avatar: avatar.value,
      avatarId: avatarId.value,
      roles: roles.value,
      username: username.value,
      registrationComplete: registrationComplete.value,
      dateOfBirth: dateOfBirth.value,
      banner_image: banner_image.value,
      phoneVerified: phoneVerified.value,
    }
  })

  const updateUser = (user: Omit<Me, 'roles'>): Promise<void> => {
    return new Promise<void>((resolve) => {
      for (const key in user) {
        const typedKey = key as keyof Omit<Me, 'roles'>
        if (user[typedKey] !== fullInfo.value[typedKey]) {
          const updatedFieldIndex = updatedFields.value.findIndex(
            (field: UpdatedField) => field.key === typedKey
          )
          if (updatedFieldIndex === -1) {
            updatedFields.value.push({ 
              key: typedKey, 
              value: user[typedKey] as string | boolean | number 
            })
          } else {
            updatedFields.value[updatedFieldIndex].value = user[typedKey] as string | boolean | number
          }
        }
      }

      // Wait for the fields to be updated before resolving
      waitForUpdate().then(() => {
        resolve()
      })
    })
  }

  const generateGraphQLMutation = <T>(
    input: Record<string, unknown>, 
    id: string, 
    mutationName: string, 
    isUserState?: boolean
  ) => {
    const fieldsToUpdate: string[] = Object.keys(input)
    const updatedFieldsTypes: Record<string, string> = {}

    fieldsToUpdate.forEach((field: string) => {
      let type: string = typeof input[field]

      if (type === 'number') {
        type = 'Int'
      } else if (type === 'boolean') {
        type = 'Boolean'
      } else if (type === 'Date') {
        type = 'Date'
      } else {
        type = 'String'
      }

      // Special cases for specific fields
      if (field === 'policyAccepted' || field === 'recieveNews') {
        type = 'JSON'
      }
      if (field === 'dateOfBirth') {
        type = 'Date'
      }
      if (field === 'user' || field === 'banner_image' || field === 'avatar') {
        type = 'ID'
      }
      updatedFieldsTypes[field] = type
    })

    const mutation = gql`
      mutation (
        ${mutationName !== "createUserPickup" && !mutationName.toLowerCase().includes('userspermissions') ? "$id: ID!" : ""}
        ${fieldsToUpdate.map(field => `$${field}: ${updatedFieldsTypes[field]}`).join(', ')}
      ) {
        ${mutationName}(
          ${mutationName !== 'createUserPickup' && !mutationName.toLowerCase().includes('userspermissions') ? 'id: $id' : ''}
          data: {
            ${fieldsToUpdate.map(field => `${field}: $${field}`).join(',')}
          }
        ) {
          data {
            id
            attributes {
              ${fieldsToUpdate.map(field => {
                if (field === 'user') {
                  return `${field} { data { id } }`
                } else if (field === 'banner_image' || field === 'avatar') {
                  return `${field} { data { attributes { url } } }`
                } else {
                  return field
                }
              }).join(', ')}
            }
          }
        }
      }
    `

    const variables: Record<string, unknown> = {}
    if (!mutationName.toLowerCase().includes('userspermissions')) {
      variables.id = id
    }
    fieldsToUpdate.forEach(field => {
      variables[field] = input[field]
    })

    const { fetch } = useGraphQLQuery(mutation, variables)
    return fetch()
  }

  watchDebounced(
    () => updatedFields.value,
    async (fields: UpdatedField[]) => {
      if (fields.length === 0) {
        return
      }

      await generateGraphQLMutation<Omit<Me, 'roles'>>(fields.reduce((acc, field) => {
        acc[field.key] = field.value
        return acc
      }, {}), id.value.toString(), 'updateUsersPermissionsUser')

      await updateMe(true)

      updatedFields.value = []
    },
    { debounce: 500, deep: true }
  )

  const updateBannerAvatar = (key: 'avatar' | 'banner_image', mediaId: number) => {
    const updatedFieldIndex = updatedFields.value.findIndex(field => field.key === key)
    if (updatedFieldIndex === -1) {
      updatedFields.value.push({ key, value: mediaId })
    } else {
      updatedFields.value[updatedFieldIndex].value = mediaId
    }
  }

  const uploadFile = (file: File) => {
    const mutation = gql`
      mutation ($file: Upload!) {
        upload(file: $file) {
          data {
            id
          }
        }
      }
    `
    const variables = { file }
    const { fetch } = useGraphQLQuery(mutation, variables)
    return fetch()
  }

  const updateAvatar = async (newAvatar: File) => {
    if (avatarId.value) {
      await removeFileFromStorage(avatarId.value)
    }

    const result = await uploadFile(newAvatar)
    updateBannerAvatar('avatar', result.upload.data.id)
    return result
  }

  const updateBanner = async (newBanner: File) => {
    const result = await uploadFile(newBanner)
    updateBannerAvatar('banner_image', result.upload.data.id)
    return result
  }

  const updateStore = () => updateMe(true)

  const sendVerificationSms = async (phoneNumber: string) => {
    const { fetch } = useGraphQLQuery(gql`
      query ($phoneNumber: String!) {
        sendVerificationSms(input: { phoneNumber: $phoneNumber }) {
          expires_in
        }
      }
    `, { phoneNumber: phoneNumber })

    await fetch()
  }
  
  const updatePhoneNumber = async (phoneNumber: string, code: string) => {
    const mutation = gql`
      mutation ($phoneNumber: String!, $code: String! ) {
        updatePhoneNumber(input: { phoneNumber: $phoneNumber, code: $code }) {
          phoneNumber
          username
          id
        }
      }
    `
    const variables = { phoneNumber: phoneNumber, code: code }

    const { fetch } = useGraphQLQuery(mutation, variables)

    await fetch()

    return updateMe()
  }

  const getPaymentMethods = async () => {
    const { fetch } = useGraphQLQuery(gql`
      query ($user_id: ID!) {
        paymentMethods(filters: {
          user: {
            id: { 
              eq: $user_id
            }
          }
        }) {
          data {
            attributes{
              bank_owner
              card_number
            }
          }
        }
      } 
    `, { user_id: id.value })

    const data = await fetch()

    if (data && data.paymentMethods) {
      const paymentMethods = data.paymentMethods.data.map((payment: PaymentMethodResponse) => {
        return {
          bank: payment.attributes.bank_owner,
          card: payment.attributes.card_number,
        }
      })
      paymentMethods.value = paymentMethods
    }
  }

  const getUserPickup = async () => {
    const { fetch } = useGraphQLQuery<{
      userPickup: {
        data: {
          attributes: {
            street: string;
          };
        };
      };
    }>(gql`
      query ($user_id: ID!) {
        userPickups(filters: {
          user: {
            id: { 
              eq: $user_id
            }
          }
        }) {
          data {
            id
            attributes{
              street
              type
              flat_number
              house_number
              city
              delivery_coordinates
              porch_number
              floor_number
              intercom_number
              courier_comment
            }
          }
        }
      }
    `, { user_id: id.value });

    const data = await fetch()

    if (data && data.userPickups) {
      const userPickups = data.userPickups.data.map((userPickup: UserPickupResponse) => {
        return {
          id: userPickup.id,
          street: userPickup.attributes.street,
          flat_number: userPickup.attributes.flat_number,
          house: userPickup.attributes.house_number,
          type: userPickup.attributes.type,
          city: userPickup.attributes.city,
          delivery_coordinates: userPickup.attributes.delivery_coordinates,
          porch_number: userPickup.attributes.porch_number,
          floor_number: userPickup.attributes.floor_number,
          intercom_number: userPickup.attributes.intercom_number,
          courier_comment: userPickup.attributes.courier_comment,
        }
      })
      userPickups.value = userPickups
    }

    return userPickups.value
  }

  const createUserPickup = async (address: UserPickupInput) => {
    const result = await generateGraphQLMutation<UserPickupInput>(address, id.value.toString(), 'createUserPickup')

    if (result && result.createUserPickup.data) {
      userPickups.value.push({
        id: result.createUserPickup.data.id,
        street: result.createUserPickup.data.attributes.street,
        flat_number: result.createUserPickup.data.attributes.flat_number,
        house_number: result.createUserPickup.data.attributes.house_number,
        type: result.createUserPickup.data.attributes.type,
        delivery_coordinates: result.createUserPickup.data.attributes.delivery_coordinates,
        porch_number: result.createUserPickup.data.attributes.porch_number,
        floor_number: result.createUserPickup.data.attributes.floor_number,
        intercom_number: result.createUserPickup.data.attributes.intercom_number,
        courier_comment: result.createUserPickup.data.attributes.courier_comment,
      });
    }

    return result
  }

  const updateUserPickup = async (pickupId: string, address: UserPickupInput) => {
    const result = await generateGraphQLMutation<UserPickupInput>(address, pickupId, 'updateUserPickup')

    if (result && result.updateUserPickup.data) {
      const index = userPickups.value.findIndex(pickup => pickup.id === pickupId);
      
      if (index !== -1) {
        userPickups.value[index] = {
          id: result.updateUserPickup.data.id,
          street: result.updateUserPickup.data.attributes.street,
          flat_number: result.updateUserPickup.data.attributes.flat_number,
          house_number: result.updateUserPickup.data.attributes.house_number,
          type: result.updateUserPickup.data.attributes.type,
        };
      }
    }

    return result
  }

  const deleteUserPickup = async (pickupId: string) => {
    const mutation = gql`
      mutation ($id: ID!) {
        deleteUserPickup(id: $id){
          data {
            id
          }
        }
      }
    `
    const variables = { id: pickupId }
    
    const { fetch } = useGraphQLQuery(mutation, variables)
    await fetch()
    
    const index = userPickups.value.findIndex(pickup => pickup.id === pickupId);
    if (index !== -1) {
      userPickups.value.splice(index, 1);
    }
  }

  const waitForUpdate = (): Promise<void> => {
    return new Promise<void>((resolve) => {
      watch(updatedFields, (fields) => {
        if (fields.length === 0) {
          resolve()
        }
      })
    })
  }

  const checkUsername = async (username: string) => {
    const { fetch } = useGraphQLQuery(gql`
      query($username: String!) {
        checkUsername(input: { username: $username }) {
          exists
        }
      }
    `, { username }, true)

    const data = await fetch()

    if (data && data.checkUsername) {
      return data.checkUsername.exists
    }
  }

  return {
    id,
    phone,
    email,
    name,
    surname,
    city,
    avatar,
    roles,
    token,
    refreshToken,
    expiresIn,
    username,
    registrationComplete,
    phoneVerified,
    userPickups,
    paymentMethods,
    dateOfBirth,
    banner_image,
    fullInfo,
    updateUser,
    updateAvatar,
    updateBanner,
    updateStore,
    sendVerificationSms,
    updatePhoneNumber,
    getPaymentMethods,
    getUserPickup,
    createUserPickup,
    updateUserPickup,
    deleteUserPickup,
    temporarlyPhone,
    me,
    waitForUpdate,
    uploadFile,
    checkUsername,
  }
})
