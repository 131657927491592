import { useUserStore } from "@/stores/user"
import { useCategoriesStore } from '@/stores/categories'
import { useMenuStore } from "@/stores/menu"

export const useInitState = async (): Promise<void> => {
  const startTime = Date.now()

  console.log('Initializing store...')
  const { status, getSession } = useAuth()
  const categoriesStore = useCategoriesStore()
  useUserStore()

  const promises = [
    (async () => {
      const start = Date.now()

      await categoriesStore.fetchCategories()
      console.log('Categories initialized.')

      const end = Date.now()
      console.log(`Categories initialization took ${end - start} milliseconds.`)
    })(),
    (async () => {
      const start = Date.now()

      await categoriesStore.fetchCategoryGroups()
      console.log('Category groups initialized.')

      const end = Date.now()
      console.log(`Category groups initialization took ${end - start} milliseconds.`)
    })(),
    (async () => {
      const start = Date.now()
      try {

        console.log('Initializing user session...')
        await getSession()
    
        const userStore = useUserStore()
        console.log("User session initialized", userStore.id)        
      } catch (e) {
        console.error('Error initializing user session:', e)
      }
      const end = Date.now()
      console.log(`User session initialization took ${end - start} milliseconds.`)
    })(),
    (async () => {
      const start = Date.now()
      const faqStore = useFAQStore()
      try {
        await faqStore.fetchFAQRoutes()
        console.log('FAQ initialized.')
      } catch(e) {
        console.log('Error when FAQ was initializing:', e)
      }

      const end = Date.now()
      console.log(`FAQ initialization took ${end - start} milliseconds.`)
    })()
  ]

  await Promise.all(promises)

  const endTime = Date.now()
  console.log(`Store initialization took ${endTime - startTime} milliseconds.`)

  if (status.value !== 'authenticated') {
    console.log('Store initialized. User not authenticated.')
    return
  }

  console.log('Store initialized.')

  if (import.meta.client) {
    useInitClientState()
  }
}

export const useInitClientState = (): void => {
  const { status } = useAuth()

  if (status.value !== 'authenticated') {
    console.log('Client store initialized. User not authenticated.')
    return
  }

  const promises = [
    (async () => {
      const notificationStore = useNotificationsStore()
      try {
        await notificationStore.fetchNotifications()
      } catch(e) {
        console.log('Error when notifications were initializing:', e)
      }
      
      console.log('Notifications initialized.')
    })(),
    (async () => {
      const bookmarksStore = useBookmarksStore()
      try {
        await bookmarksStore.fetchBookmarks()
      } catch(e) {
        console.log('Error when bookmarks were initializing:', e)
      }
      
      console.log('Bookmarks initialized.')
    })()
  ]
  
  Promise.all(promises)
  .then(() => console.log('Client store initialized.'))
}