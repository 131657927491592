<script setup lang="ts">
const route = useRoute()

const scrollable = computed(() => route.path === '/welcome')

</script>

<template>
  <NuxtLoadingIndicator color="linear-gradient(90deg, #FEA383 0%, #FEA083 59.5%, #FF175D 100%)"/>
  <div>
    <TheMainLoader :scrollable="scrollable"></TheMainLoader>
    <NuxtLayout>
      <NuxtPage/>
    </NuxtLayout>
  </div>
</template>

<style lang="scss">
  .fix-enter-active,
  .fix-leave-active {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .fix-leave-active:not(.fix-leave-from) {
    position: static;
  }

  .slide-left-enter-active,
  .slide-left-leave-active,
  .slide-right-enter-active,
  .slide-right-leave-active {
    transition: transform 1s;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  .slide-left-enter-from, .slide-right-leave-to {
    transform: translateX(0%);
  }
  .slide-right-enter-from, .slide-left-leave-to {
    transform: translateX(calc(-100% - get-vw(98)));
  }

  @include mobile {

    .slide-right-enter-from,
    .slide-left-leave-to {
      transform: translateY(-100%);
    }

    .slide-left-enter-from,
    .slide-right-leave-to {
      transform: translateY(100%);
    }
  }
</style>